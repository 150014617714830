import { Suspense } from "react";
import { Await, useLoaderData } from "@remix-run/react";
import { defer, LoaderArgs } from "@shopify/remix-oxygen";
import { flattenConnection } from "@shopify/hydrogen";
import { Article, Blog } from "@shopify/hydrogen/storefront-api-types";

import { PAGINATION_SIZE } from "~/libs/const";
import { getDatabase } from "~/libs/notion.server";
import { QUERY_CONSTANTS, STOREFRONT_QUERY } from "~/libs/query";
import { 
  Hero, 
  PartnerClients, 
  Portfolio,
  Articles,
  Testimonial,
  Services
} from "./components";


export async function loader({ params, context:{ env, storefront} }: LoaderArgs) {
  const {language, country} = storefront.i18n;
  
  if (
    params.lang &&
    params.lang.toLowerCase() !== `${language}-${country}`.toLowerCase()
  ) {
    // If the lang URL param is defined, yet we still are on `EN-US`
    // the the lang param must be invalid, send to the 404 page
    throw new Response(null, {status: 404});
  }

  const { blog: portfolio} = await storefront.query<{
    blog: Blog;
  }>(STOREFRONT_QUERY.PORTFOLIOS_QUERY, {
    variables: {
      blogHandle: QUERY_CONSTANTS.PORTFOLIO_HANDLE,
      pageBy: PAGINATION_SIZE,
      namespace: QUERY_CONSTANTS.PORTFOLIO_META_NAMESPACE,
      key: QUERY_CONSTANTS.PORTFOLIO_META_KEY_LINK,
      language,
    },
  });

  const portfolios = flattenConnection(portfolio.articles).map((article: Article) => {
    const {publishedAt} = article;
    return {
      ...article,
      publishedAt: new Intl.DateTimeFormat(`${language}-${country}`, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }).format(new Date(publishedAt!)),
    };
  });

  const posts = await getDatabase(env.NOTION_POSTS_TABLE_ID || '');

  return defer({
    posts,
    portfolios
  });
}

export default function ContentLayout() {
  const { posts, portfolios } = useLoaderData<typeof loader>();
  
  return (
    <>
      <Hero />
      <Services />
      <hr className="border border-primary"/>
      <PartnerClients />
      <hr className="border border-primary"/>
      <Testimonial />
      <hr className="border border-primary"/>
      <Suspense>
        <Await resolve={portfolios}>
          {portfolios => 
            <>
              <Portfolio portfolio={portfolios} />
              <hr className="border border-primary"/>
            </>
          }
        </Await>
      </Suspense>
      <Suspense>
        <Await resolve={posts}>
          {posts => 
            <>
              <Articles posts={posts} />
              <hr className="border border-primary"/>
            </>
          }
        </Await>
      </Suspense>
    </>
  );
}
